html,
body,
#root {
    height: 100%;
}

.form-field {
    margin-bottom: 1em;
}

.spreadsheet-table {
    .form-field {
        margin-bottom: 0;
    }
}

.bottom-margin {
    margin-bottom: 2em;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.show-newlines {
    white-space: pre-wrap;
}

.nowrap {
    white-space: nowrap;
}

.profile-grid {
    padding: 1em;
}

.table-wrapper {
    min-height: 200px;
}

.hover-pointer:hover {
    cursor: pointer;
}

.meta-info {
    font-size: 0.75em;
}

.file-upload-form {
    margin-bottom: 2em;
}

.vertical-margin {
    margin-top: 2em;
    margin-bottom: 2em;
}

.hidden {
    display: none;
}

.inline {
    display: inline-block;
}
