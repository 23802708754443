.page-loader-wrapper {
    background-color: #f5f5f5;
    height: 100%;
    position: relative;
    text-align: center;

    .MuiCircularProgress-root {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
